export class Apps {
  appId: string;
  name: string;
  startDate: string;
  endDate: string;
  description: string;
  logoImage: string;
  startDateReadonly: Date;
  endDateReadonly: Date;
  templateName: string;
  version: string;
  nextVersion: string;
  publishStatus: boolean;
  upgradeStatus: number;


  constructor(appId?: string, name?: string, startDate?: string, endDate?: string, description?: string,
              logoImage?: string, templateName?: string, version?: string, nextVersion?: string, publishStatus?: boolean) {
    this.appId = appId;
    this.name = name;
    this.startDate = startDate;
    this.endDate = endDate;
    this.description = description;
    this.logoImage = logoImage;
    this.templateName = templateName;
    this.version = version;
    this.nextVersion = nextVersion;
    this.publishStatus = publishStatus;
    this.upgradeStatus = 1; // temp code
  }
}









