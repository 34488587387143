import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Apps} from '../dtos/apps';
import {throwError} from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import {SpaceCrowdAuthService} from './spacecrowd-auth-service';
import {
  ConfirmUpgradeRequestDto,
  ConfirmUpgradeResponseDto,
  UpgradeOptionsDto,
  UpgradeRequestDto
} from '../dtos/upgrade/upgrade-request-dto';


@Injectable()
export class AppsService {

  // baseUrl = 'http://localhost:59004/api/';
  baseUrl = 'https://conf.confstudio.com/api/';

  constructor(private http: HttpClient, private spAuthService: SpaceCrowdAuthService) { }

  getAllApps() {
    this.spAuthService.getAuthToken();

    return  this.http.get<Apps[]>(this.baseUrl + 'conference/all', this.spAuthService.getHttpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  getApp(id) {
    return  this.http.get<Apps>(this.baseUrl + 'conference/' + id, this.spAuthService.getHttpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  updateTemplate(id: string, template: string) {
    if (id == null) { return; }
    if (template == null) {return; }

    return this.http.patch<Apps> (this.baseUrl + 'conference/' + id + '/update-template/' + template, '{}',
      this.spAuthService.getHttpOptions() )
      .pipe(
        catchError(this.handleError)
      );
  }

  createApp(app: Apps) {
    if (app == null) { return; }
    return this.http.post<Apps> (this.baseUrl + 'conference/', app, this.spAuthService.getHttpOptions() )
      .pipe(
        catchError(this.handleError)
      );
  }

  modifyApp(appInfo: Apps) {
    if (appInfo == null) { return; }
    return this.http.put<Apps>(this.baseUrl + 'conference/' + appInfo.appId, appInfo, this.spAuthService.getHttpOptions() )
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteApp(appInfo: Apps) {
    console.log('Going to delete the guide 2 ' + appInfo.appId);
    const url: string = this.baseUrl + 'conference/' + appInfo.appId;
    console.log(url);
    return this.http.delete(url, this.spAuthService.getHttpOptions() )
      .pipe(
        catchError(this.handleError)
      );
  }

  GetUpgradeOptions(appId: string) {
    console.log('Going upgrade options for ' +  appId + '.');
    const url: string = this.baseUrl + 'cart/' + appId + '/upgradeoptions';
    console.log(url);
    return this.http.get<UpgradeOptionsDto>(url, this.spAuthService.getHttpOptions() )
      .pipe(
        catchError(this.handleError)
      );
  }

  UpgradeApp(upgradeRequest: UpgradeRequestDto) {
    console.log('Going to request for upgrade for ' +  upgradeRequest.appId + '.');
    const url: string = this.baseUrl + 'cart/' + upgradeRequest.appId + '/update';
    console.log(url);
    console.log(upgradeRequest);
    return this.http.post<UpgradeRequestDto>(url, upgradeRequest, this.spAuthService.getHttpOptions() )
      .pipe(
        catchError(this.handleError)
      );
  }

  getCart(appId: string, cartId: string) {
    console.log('Get cart for ' + appId + '.');
    const url: string = this.baseUrl + 'cart/' + appId + '/get/' + cartId;
    console.log(url);
    return this.http.get<ConfirmUpgradeResponseDto>(url, this.spAuthService.getHttpOptions() )
      .pipe(
        catchError(this.handleError)
      );
  }

   confirmApp(upgradeConfirmRequest: ConfirmUpgradeRequestDto) {
    console.log('Going to request for upgrade for ' +  upgradeConfirmRequest.appId + '.');
    const url: string = this.baseUrl + 'cart/' + upgradeConfirmRequest.appId + '/confirm';
    console.log(url);
    return this.http.post<ConfirmUpgradeResponseDto>(url, upgradeConfirmRequest, this.spAuthService.getHttpOptions() )
      .pipe(
        catchError(this.handleError)
      );
  }

  handleError = (error: HttpErrorResponse) => {
    let errorMessage = 'Something bad happened; please try again later.';
    console.log(errorMessage)
    if (this.spAuthService.challengeResponse(error)) {
      return [];
    } else {
      if (error.error instanceof ErrorEvent) {
        // A client-side or network error occurred. Handle it accordingly.
        console.error('An error occurred:', error.error.message);
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        console.error(
          `Backend returned code ${error.status}, ` +
          `body was: ${error.error}`);

        if (error.error) {
          errorMessage = error.error;
        }

      }
      // return an observable with a user-facing error message
      return throwError(errorMessage);
    }
  }






}
