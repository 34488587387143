import {Injectable} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';

@Injectable()
export class SpaceCrowdCookieService {

  constructor(
    private cookieService: CookieService) { }

  setCookie(name: string, val: string) {

    // todo: Set Time to 7 days
    const date = new Date();
    date.setTime(date.getTime() + (7 * 24 * 60 * 60 * 1000));

    // Set it
    this.cookieService.set(name, val);
  }


  getCookie(name: string) {
    return this.cookieService.get(name);
  }


  deleteCookie(name: string) {
    this.cookieService.delete(name);
  }
}
