import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Apps} from '../dtos/apps';
import {throwError} from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import {SectionInfo} from '../dtos/section-info';
import {AppPublishConfirmationDto} from '../dtos/publish/app-publish-confirmation-dto';
import {SpaceCrowdAuthService} from './spacecrowd-auth-service';



@Injectable()
export class AppSectionsService {

  // baseUrl = 'http://localhost:59003/api/';
  baseUrl = 'https://manager.confstudio.com/api/';
  constructor(private http: HttpClient, private spAuthService: SpaceCrowdAuthService) { }

  getAllSections(appId: string) {
    return  this.http.get<Apps>(this.baseUrl + 'sections/' + appId + '/list', this.spAuthService.getHttpOptions() )
      .pipe(
        catchError(this.handleError)
      );
  }

  getSectionInfo(appId: string, sectionId: string, parentId: string, miniAppId: number) {
    let url = this.baseUrl + 'sections/' + appId + '/sectioninfo/' + sectionId + '/' + miniAppId;

    if (parentId != null && parentId !== '') {
      url = url + '/' + parentId;
    }

    return  this.http.get<SectionInfo>(url, this.spAuthService.getHttpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  modifySectionInfo(sectionInfo: SectionInfo, appId: string) {
    if (sectionInfo == null) { return; }
    return this.http.put<Apps>(this.baseUrl + 'sections/' + appId + '/sectioninfo', sectionInfo, this.spAuthService.getHttpOptions() )
      .pipe(
        catchError(this.handleError)
      );
  }

  createDefaultSections(appId: string) {
    const theApp = new Apps();
    theApp.appId = appId;
    if (appId == null) { return; }
    return this.http.patch<SectionInfo[]> (this.baseUrl  + 'sections/' +  appId + '/createsections', theApp,
      this.spAuthService.getHttpOptions() )
      .pipe(
        catchError(this.handleError)
      );
  }

  publishApp(appId: string) {
    const theApp = new Apps();
    theApp.appId = appId;
    if (appId == null) { return; }
    return this.http.patch<AppPublishConfirmationDto> (this.baseUrl  + 'sections/' +  appId + '/publish-app', theApp,
      this.spAuthService.getHttpOptions() )
      .pipe(
        catchError(this.handleError)
      );
  }

  privatePublishApp(appId: string) {
    const theApp = new Apps();
    theApp.appId = appId;
    if (appId == null) { return; }
    return this.http.patch<AppPublishConfirmationDto> (this.baseUrl  + 'sections/' +  appId + '/publish-private-app', theApp,
      this.spAuthService.getHttpOptions() )
      .pipe(
        catchError(this.handleError)
      );
  }

  unpublishApp(appInfo: Apps) {
    console.log("Going to unpublish the guide " + appInfo.appId)
    var url = this.baseUrl + 'conference/' + appInfo.appId;
    console.log(url);
    return this.http.patch<AppPublishConfirmationDto> (this.baseUrl  + 'sections/' +  appInfo.appId + '/unpublish-app', appInfo,
      this.spAuthService.getHttpOptions() )
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: any) {

    if (this.spAuthService && this.spAuthService.challengeResponse(error)) {
      return [];
    }

    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    if (error.error.errors) {
      return throwError(
        error.error.errors);
    }
    else {
      return throwError(
        error.error.error);
    }
  }


  // private handleError(error: HttpErrorResponse) {
  //   if (error.error instanceof ErrorEvent) {
  //     // A client-side or network error occurred. Handle it accordingly.
  //     console.error('An error occurred:', error.error.message);
  //   } else {
  //     // The backend returned an unsuccessful response code.
  //     // The response body may contain clues as to what went wrong,
  //     console.error(
  //       `Backend returned code ${error.status}, ` +
  //       `body was: ${error.error}`);
  //   }
  //   // return an observable with a user-facing error message
  //   return throwError(
  //     'Request failed: ' + error.error);
  // }
}
