import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {SectionInfo} from '../dtos/section-info';
import {AppSectionsService} from '../services/app-sections.service';
import {SpaceCrowdAuthService} from '../services/spacecrowd-auth-service';
import {ActivatedRoute, Router, ActivationEnd} from '@angular/router';
import {AppsService} from '../services/apps.service';
import { timer } from 'rxjs';

@Component({
    selector: 'app-dashboard',
    templateUrl: './common-layout.component.html',
    providers: [AppsService, AppSectionsService]
})

export class CommonLayoutComponent implements OnInit {

    constructor(private appSectionService: AppSectionsService, private spAuthService: SpaceCrowdAuthService,
                private router: Router, private route: ActivatedRoute ) {
        this.app = {
            layout: {
              sidePanelOpen: false,
              isMenuOpened: true,
              isMenuCollapsed: false,
              themeConfigOpen: false,
              rtlActived: false,
              searchActived: false
            }
        };

        this.headerThemes = ['header-default', 'header-primary', 'header-info', 'header-success', 'header-danger', 'header-dark'];
        this.changeHeader = changeHeader;

        function changeHeader(headerTheme) {
            this.headerSelected = headerTheme;
        }

        this.sidenavThemes = ['sidenav-default', 'side-nav-dark'];
        this.changeSidenav = changeSidenav;

        function changeSidenav(sidenavTheme) {
            this.sidenavSelected = sidenavTheme;
        }

      router.events.subscribe((val) => {
        if (val instanceof ActivationEnd) {
          if (val != null && val.snapshot != null && val.snapshot.params != null) {
            const theId = val.snapshot.params.id;
            const theAppId = val.snapshot.params.appId;
            const theTitle = val.snapshot.data.title;

            if (theAppId != null && theAppId !== '' && theAppId !== undefined) {
              this.validateRouteChanges(theAppId);
            } else if (theId != null && theId !== '' && theId !== undefined) {
              this.validateRouteChanges(theId);
            } else if (theTitle != null && theTitle !== '' && theTitle !== undefined) {
              this.validateRouteChanges('');
            }
          }
        }
      });
    }

    public app: any;
    public headerThemes: any;
    public changeHeader: any;
    public sidenavThemes: any;
    public changeSidenav: any;
    public headerSelected: any;
    public sidenavSelected: any;
    public searchActived: any;
    public searchModel: any;

    public error = '';
    sectionInfoList: SectionInfo[];
    loading = true;
    id = '';
    private sub: any;

  @ViewChild('sectionsMenu', {static: true}) theSectionsMenu: ElementRef<HTMLElement>;
  @ViewChild('sectionsMenuSpan', {static: true}) theSectionsMenuSpan: ElementRef<HTMLElement>;

  @ViewChild('appMenu', {static: true}) theAppMenu: ElementRef<HTMLElement>;
  @ViewChild('appMenuSpan', {static: true}) theAppMenuSpan: ElementRef<HTMLElement>;

  ngOnInit() {
  }

  validateRouteChanges(newId: string) {
    // this.route.paramMap.forEach(x => console.log(x.keys))
    // this.route.snapshot.routeConfig.data.forEach(x => console.log(x))
    // this.id = this.route.paramMap['appId']; // (+) converts string 'id' to a number
    // var sectionId = this.route.params['sectionId']; // (+) converts string 'id' to a number
    // if id is null or undefined

    // console.log('The Id is ' + this.id);
    // console.log('The Section Id is ' + sectionId);

    // tslint:disable-next-line:triple-equals
    if (newId == null || newId === '') {
      this.id = '';
      this.sectionInfoList = [];
      this.openAppDropdown();
    } else if (this.id === newId) {
      // do nothing
    } else {
      console.log('Fetching Data');
      this.id = newId;
      this.createDefaultApps();
      this.openSectionsDropdown();
    }
  }

    getName() {
      const name = localStorage.getItem('SC-LoginName');
      if (name == null || name === '') {
        return 'User';
      } else {
        return name;
      }
    }

  logoutUser() {
      localStorage.removeItem('SC-Token');
      localStorage.removeItem('SC-UserId');
      localStorage.removeItem('SC-LoginName');
      localStorage.removeItem('SC-LoginExp');
  }

  onViewProfile() {
    this.router.navigate(['/account/view-profile']);
  }

  onChangePassword() {
    this.router.navigate(['/account/update-password']);
  }

  createDefaultApps() {
    console.log('Server Call Id is ' + this.id);
      this.appSectionService.getAllSections(this.id)
      .subscribe(
        (data: SectionInfo[]) => this.sectionInfoList =  data.map(dto => {
          this.loading = false;
          return new SectionInfo(dto.appId, dto.parentId, dto.sectionId, dto.name, dto.description, dto.miniAppType,
            dto.miniAppTypeName, dto.icon, dto.canDeactivate, dto.deactivated);
        }), // success path
        error => this.error = error // error path
      );
  }

  onManage(sectionId, miniAppType) {
    // this.router.navigate(['/apps/create-app', { id: id  }]);

    if (miniAppType === 1) { // content section
      this.router.navigate(['/content/content-update', { appId: this.sectionInfoList[0].appId, sectionId: sectionId }]);
    } else if (miniAppType === 2) { // schedule section
      this.router.navigate(['/schedule/tracks-manager', { appId: this.sectionInfoList[0].appId, sectionId: sectionId }]);
    } else if (miniAppType === 3) { // my schedule section
      // this.router.navigate(['/my-schedule/default-events', { appId: this.sectionInfoList[0].appId, sectionId: sectionId }]);
    } else if (miniAppType === 4) { // inbox section
      this.router.navigate(['/feed/feed-manager', { appId: this.sectionInfoList[0].appId, sectionId: sectionId }]);
    } else if (miniAppType === 5) { // bookmarks section
      // this.router.navigate(['/bookmarks/default-bookmarks', { appId: this.sectionInfoList[0].appId, sectionId: sectionId }]);
    } else if (miniAppType === 6) { // maps section
      this.router.navigate(['/location/address-list', { appId: this.sectionInfoList[0].appId, sectionId: sectionId }]);
    } else if (miniAppType === 7) { // images section
      this.router.navigate(['/images/image-manager', { appId: this.sectionInfoList[0].appId, sectionId: sectionId }]);
    } else if (miniAppType === 8) { // people section
      this.router.navigate(['/people/people-list', { appId: this.sectionInfoList[0].appId, sectionId: sectionId }]);
    } else if (miniAppType === 9) { // exhibitor section
      this.router.navigate(['/exhibitor/exhibitor-list', { appId: this.sectionInfoList[0].appId, sectionId: sectionId }]);
    } else if (miniAppType === 10) { // sponsor section
      this.router.navigate(['/sponsor/sponsor-list', { appId: this.sectionInfoList[0].appId, sectionId: sectionId }]);
    } else if (miniAppType === 11) { // notification section
      this.router.navigate(['/notification/notification-manager', { appId: this.sectionInfoList[0].appId, sectionId: sectionId }]);
    }
  }

  shouldHideMiniAppType(miniAppType) {
    return miniAppType === 3 || miniAppType === 5;
  }


  goToAppHome() {
    this.router.navigate(['/apps/app-manager', { id: this.id  }]);
  }
  openSectionsDropdown() {

    if (!this.theSectionsMenu) {
      console.warn('this.theSectionsMenu is null');
      return;
    }

    console.log('open section dropdown ' + this.theSectionsMenu.nativeElement);
    const sectionsMenuElement: HTMLElement = this.theSectionsMenu.nativeElement;
    const sectionMenuClassName = sectionsMenuElement.className;
    console.log('open section dropdown class name ' + sectionMenuClassName);
    if (!sectionMenuClassName.includes('open')) {
      console.log('going to click the sections span');
      const sectionsMenuSpanElement: HTMLElement = this.theSectionsMenuSpan.nativeElement;
      console.log('going to click the sections span ' + sectionsMenuSpanElement);
      sectionsMenuSpanElement.click();

      if (!sectionsMenuElement.className.includes('open')) {
        timer(500).subscribe(x => {
          if (!sectionsMenuElement.className.includes('open')) {
            sectionsMenuSpanElement.click();
          }
        });
      }
    }
  }
  openAppDropdown() {
    console.log('open app dropdown ' +  this.theAppMenu);
    if (!this.theAppMenu) {
      console.warn('this.theAppMenu is null');
      return;
    }

    const appMenuElement: HTMLElement = this.theAppMenu.nativeElement;
    if (!appMenuElement.className.includes('open')) {
      const sectionsMenuSpanElement: HTMLElement = this.theAppMenuSpan.nativeElement;
      sectionsMenuSpanElement.click();
      if (!appMenuElement.className.includes('open')) {
        timer(500).subscribe(x => {
          if (!appMenuElement.className.includes('open')) {
            sectionsMenuSpanElement.click();
          }
        });
      }
    }
  }
}
