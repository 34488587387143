export class SectionInfo {
  appId: string;
  parentId: string;
  sectionId: string;
  miniAppType: number;
  miniAppTypeName: string;
  name: string;
  description: string;
  icon: string;
  canDeactivate: boolean;
  deactivated: boolean;
  createDate: string;
  updateDate: string;
  miniAppTypeValidator: number;
  parentName: string;

  constructor(appId?: string, parentId?: string, sectionId?: string, name?: string, description?:
    string, miniAppType?: number, miniAppTypeName?: string, icon?: string, canDeactivate?: boolean,
              deactivated?: boolean, parentName?: string) {
    this.appId = appId;
    this.parentId = parentId;
    this.sectionId = sectionId;
    this.name = name;
    this.description = description;
    this.miniAppType = miniAppType;
    this.miniAppTypeName = miniAppTypeName;
    this.icon = icon;
    this.parentName = parentName;

    if (deactivated == null) {
      deactivated = false;
    }

    this.deactivated = deactivated;
    this.canDeactivate = canDeactivate;

  }
}


