import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {throwError} from 'rxjs';

@Injectable()
export class SpaceCrowdAuthService {

  tokenKey = 'SC-Token';
  userIdKey = 'SC-UserId';
  loginNameKey = 'SC-LoginName';
  loginExpKey = "SC-LoginExp";

  constructor(private router: Router) { }

  loginUser(token: string, userId: string, name: string, loginExp: string) {
    localStorage.setItem(this.tokenKey, token);
    localStorage.setItem(this.userIdKey, userId);
    localStorage.setItem(this.loginNameKey, name);
    localStorage.setItem(this.loginExpKey, loginExp)
  }

  logoutUser() {
    localStorage.removeItem(this.tokenKey);
    localStorage.removeItem(this.userIdKey);
    localStorage.removeItem(this.loginNameKey);
    localStorage.removeItem(this.loginExpKey);
  }

  getAuthToken() {
    try {
      return localStorage.getItem(this.tokenKey);
    }
    catch (e) {
      this.goToLogin();
    }
  }

  getAuthUserId() {
    try {
      return localStorage.getItem(this.userIdKey);
    }
    catch (e) {
      this.goToLogin();
    }
  }

  getUTCExpDate() {
    return localStorage.getItem(this.loginExpKey);
  }

  validateLoggedIn() {
    const token = this.getAuthToken();
    const userId = this.getAuthUserId();
    var expDateStr = this.getUTCExpDate();

    if (token == null || userId == null || token === '' || userId === '' || expDateStr == null || expDateStr == '') {
      this.goToLogin();
      return false;
    }
    else {
      var expDate = new Date(expDateStr);
      if (new Date() >= expDate){
        this.goToLogin();
        return false;
      }
    }
    return true;
  }

  // headers
  private getHttpHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization' : this.getAuthToken(),
      'SC-UserId' : this.getAuthUserId()
    });
  }

  getHttpOptions() {
    return {headers: this.getHttpHeaders()};
  }
  ///

  challengeResponse(error: HttpErrorResponse) {
    if (error.status === 401) { // user is not logged in.
      this.goToLogin();
      return true;
    } else if  (error.status === 404) { // user is logged in but in the wrong app. Forwarding to app list
      this.goToAppList();
      return true;
    } else if (error.status == 403) {
      return throwError("The request failed. You don't have permission to perform this request.")
    }

    return false;
  }

  goToAppList() {
    this.router.navigate(['/apps/apps-list', { }]);
  }

  goToLogin() {
    this.router.navigate(['/manager/sign-in', { }]);
  }
}
