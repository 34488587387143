import { Component } from '@angular/core';
import {NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import {NgbDateUSParserFormatter} from './shared/ui-helper/ngb-date-us-parser-formatter';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    providers: [{provide: NgbDateParserFormatter, useClass: NgbDateUSParserFormatter}]
})
export class AppComponent {
}
